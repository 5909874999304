import {
    Modal,
    Text,
    Flex,
    Button,
    Image,
} from '@mantine/core';

import { useStore } from '../../hooks/use-store';
import { observer } from 'mobx-react';
import { NOTIFICATION_SERVICE } from '../../components/notifications/NotificationService';
import image from './creditsteasersubscription.jpg'
import { useState } from 'react';

export const Element = (props: {
    opened: boolean
    close: () => void
}) => {

    const [loading, setLoading]= useState(false)
    const {
        dataStore: { appStore },
    } = useStore();

    return (
        <Modal
            opened={props.opened}
            styles={{}} onClose={props.close} title={' '} size={'xl'} padding={20}>
            <Flex
                gap={32}
                m={12}
            >
                <div style={{ width: '60%' }}>
                    <Image bg={'#f0f2f1'} src={image} w={'100%'} style={{ borderRadius: '10px' }}></Image>
                </div>
                <div style={{ width: '40%' }}>
                    <Flex direction={'column'} gap={2}>
                        <Text fz={16}>Buy access to run generation</Text>
                        <Flex bg={'#f0f2f1'} w={'100%'} style={{ borderRadius: '4px' }} p={12} mt={6} mb={6} direction={'column'} gap={8}>
                            <Text fz={14} fw={'700'}>- 1000 credits included</Text>
                            <Text fz={14}>- One time payment, no subscription</Text>
                            <Text fz={14}>- Create & download AI backgrounds trained ethically on rights-save imagery</Text>
                            <Text fz={14}>- Upscale up to 6K</Text>
                        </Flex>
                        <Button
                            mt={20}
                            fullWidth={true}
                            loading={loading}
                            styles={{
                                root: {
                                    color: 'rgba(230, 230, 230, 1)',
                                    height: '42px',
                                    fontSize: '16px',
                                    background: '#f53c02',
                                    border: 'none',
                                    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25), 0px 0.5px 0px 0px rgba(255, 255, 255, 0.15) inset',
                                }
                            }}
                            variant="default"
                            onClick={async () => {
                                setLoading(true)
                                const res = await appStore.getAccessCheckoutLink()
                                setLoading(false)
                                if (res.url) {

                                    let a = document.createElement('a');
                                    a.target = '_blank';
                                    a.href = res.url;
                                    a.click();
                                    props.close()
                                } else {
                                    NOTIFICATION_SERVICE.errorNotification('Something went wrong')
                                }
                            }}
                        >
                            Buy access for $99
                        </Button>
                        <Text fz={13} mt={12}>Require company or corporate licenses? <a href="mailto:mail@maground.com?subject=Corporate%20AI%20pricing">Get in Touch</a></Text>
                    </Flex>
                </div>

            </Flex>


        </Modal >
    )
}

export const AccessBillingModal = observer(Element);