import { Button, Flex, Text, rem, Textarea, Progress, Select, ColorInput, Checkbox, Accordion, Slider } from "@mantine/core";
import { useStore } from "../../../hooks/use-store";
import { useState } from "react";
import { NOTIFICATION_SERVICE } from "../../../components/notifications/NotificationService";
// import html2canvas from 'html2canvas';
import { PipelineParameters } from "./Pipeline";
import ImageObserved from "./Image";
import { getLoraOptons } from "../../../types";
import { PromptGeneratorButton } from "./PromptGeneratorButton";
import { GalleryItem, priceMap } from "./Main";

export const CustomModelWorkflow = (model: {
    setBillingSubscriptionModalOpen: () => void,
    selectedModel: any,
    gallery: GalleryItem[],
    setLatestCustomModelsRun: any,
    latestCustomModelsRun: any,
    runCreatePipeline: any,
    setPipelines: any,
    setBillingModalOpen: any,
    pipelines: any,
    // open: boolean,
    // onClose: () => void, defaultImage?: string,
    // onGen: (model: { image: string, mask: string, prompt: string, negativePrompt: string }) => void
}) => {

    // const [prompt, setPrompt] = useState('');
    // const [negativePrompt, setNegativePrompt] = useState(defaultNegative);

    // // const isMobile = useIsMobile()
    // const [imageSrc, setImageSrc] = useState('');
    // const [maskSrc, setMaskSrc] = useState('');

    // const [customLoading, setCustomLoading] = useState(false);

    const [modelParams, setModelParams] = useState({
        prompt: '',
        aspect_ratio: "1:1",
        lora_scale: '1',
        secondLoraName: '',
        secondLoraScale: '1',
        motionBlur: '',
        carPerspective: [
            'Front View',
            'Rear View',
            'Side View (Left)',
            'Side View (Right)',
            'Front 3/4 View (Left)',
            'Front 3/4 View (Right)',
            'Rear 3/4 View (Left)',
            'Rear 3/4 View (Right)'
        ],
        color: '#004f18',
        steps: 28,
        cfg: 3.5,
    });

    const generateButtton = (model: { credits: number, text?: string, intercom?: string }) => {
        return <Flex
            gap={12}
            align={'center'}
            data-intercom-target={
                model.intercom
            }>
            <Text>
                {typeof model.text === 'string' ? model.text : 'Generate'}
            </Text>
            <Flex align={'center'} style={{
                borderRadius: '12px', background: '#f85e39',
                padding: '0 8px'
            }}>

                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.98014 1.33337C4.30014 1.33337 1.31348 4.32004 1.31348 8.00004C1.31348 11.68 4.30014 14.6667 7.98014 14.6667C11.6601 14.6667 14.6468 11.68 14.6468 8.00004C14.6468 4.32004 11.6668 1.33337 7.98014 1.33337ZM10.4801 8.23337L8.00014 11.0534L7.70681 11.3867C7.30014 11.8467 6.96681 11.7267 6.96681 11.1067V8.46671H5.83348C5.32014 8.46671 5.18014 8.15337 5.52014 7.76671L8.00014 4.94671L8.29348 4.61337C8.70014 4.15337 9.03348 4.27337 9.03348 4.89337V7.53337H10.1668C10.6801 7.53337 10.8201 7.84671 10.4801 8.23337Z" fill="white" />
                </svg>
                <Text >{model.credits}</Text>
            </Flex >
        </Flex>
    }

    const {
        uiStore: { authStore },
    } = useStore();


    return (
        <Flex w={'100%'} mt={12} pt={16} pb={16} pl={20} pr={20} style={{ borderRadius: '4px' }} gap={32}>

            <Flex direction={'column'} miw={'300px'} w={'300px'} gap={12}>
                <Text fz={20}>Model: {model.selectedModel?.data?.name}</Text>
                <Flex justify={'space-between'}>
                    {(model.selectedModel?.data?.images || [])
                        .slice(0, 6)
                        .map((img, index) => {
                            return <Flex
                                key={index}
                                h="auto"
                                p={2}
                                w={'33.3%'}
                                onClick={() => {
                                    // setBacgroundSrc({
                                    //     url: img
                                    // })
                                }}
                            >
                                <ImageObserved
                                    width={'100%'}
                                    height={'auto'}
                                    src={img}
                                />
                            </Flex>
                        })
                    }
                </Flex>

                <Select
                    w="300px"
                    size="sm"
                    mt={12}
                    description=""
                    label="Select Car LORA"
                    placeholder="Pick value"
                    value={modelParams.secondLoraName}
                    onChange={(value) => {
                        if (value === 'custom') {
                            window.open('https://tally.so/r/w8JYoP', '_blank')
                        } else {
                            setModelParams({
                                ...modelParams,
                                secondLoraName: value!
                            })
                        }
                    }}
                    mb={10}
                    data={[
                        ...getLoraOptons(authStore.currentUser?.id),
                        { value: 'custom', label: 'Upload Custom Lora' }
                    ]
                    }
                />

                {
                    modelParams.secondLoraName &&
                    (
                        <ColorInput
                            label="Color of the car"
                            size="sm"
                            // radius={2}
                            value={modelParams.color}
                            withPicker
                            styles={{
                                root: {
                                },
                                input: {
                                    // backgroundColor: '#ffffff',
                                    // color: 'white',
                                    // borderColor: '#464646',
                                }
                            }}
                            onChangeEnd={(e: any) => {
                                setModelParams({
                                    ...modelParams,
                                    color: e!
                                })
                            }}
                            format="hex"
                        />
                    )
                }
                {
                    modelParams.secondLoraName &&
                    (
                        <>
                            <Text
                                fz={14}
                                mt={12}
                                size="xs">
                                Select the car perspectives
                            </Text>
                            {
                                [
                                    { value: 'Front View', label: 'Front View' },
                                    { value: 'Rear View', label: 'Rear View' },
                                    { value: 'Side View (Left)', label: 'Side View (Left)' },
                                    { value: 'Side View (Right)', label: 'Side View (Right)' },
                                    { value: 'Front 3/4 View (Left)', label: 'Front 3/4 View (Left)' },
                                    { value: 'Front 3/4 View (Right)', label: 'Front 3/4 View (Right)' },
                                    { value: 'Rear 3/4 View (Left)', label: 'Rear 3/4 View (Left)' },
                                    { value: 'Rear 3/4 View (Right)', label: 'Rear 3/4 View (Right)' },
                                ].map((side) => (
                                    <Checkbox
                                        mt={2}
                                        mb={2}
                                        color="dimmed"
                                        size={'xs'}
                                        styles={{
                                            input: {
                                                borderRadius: 2,
                                                borderColor: '#464646',
                                                backgroundColor: 'transparent',
                                            }
                                        }}
                                        label={side.label}
                                        checked={modelParams.carPerspective.includes(side.value)}
                                        onChange={(e) => {
                                            if (modelParams.carPerspective.includes(side.value)) {
                                                setModelParams({
                                                    ...modelParams,
                                                    carPerspective: modelParams.carPerspective.filter(itm => itm !== side.value)
                                                })
                                            } else {
                                                setModelParams({
                                                    ...modelParams,
                                                    carPerspective: [...modelParams.carPerspective, side.value]
                                                })
                                            }
                                        }}
                                    />
                                ))

                            }
                        </>
                        // <Select
                        //   w="300px"
                        //   size="sm"
                        //   mt={12}
                        //   description=""
                        //   label="Select Car perspective"
                        //   placeholder="Pick value"
                        //   value={modelParams.carPerspective}
                        //   onChange={(value) => {
                        //     setModelParams({
                        //       ...modelParams,
                        //       carPerspective: value!
                        //     })
                        //   }}
                        //   mb={10}
                        //   data={[
                        //     { value: '', label: 'None' },
                        //     { value: 'Front View', label: 'Front View' },
                        //     { value: 'Rear View', label: 'Rear View' },
                        //     { value: 'Side View (Left)', label: 'Side View (Left)' },
                        //     { value: 'Side View (Right)', label: 'Side View (Right)' },
                        //     { value: 'Top View', label: 'Top View' },
                        //     { value: 'Bottom View', label: 'Bottom View' },
                        //     { value: 'Front 3/4 View (Left)', label: 'Front 3/4 View (Left)' },
                        //     { value: 'Front 3/4 View (Right)', label: 'Front 3/4 View (Right)' },
                        //     { value: 'Rear 3/4 View (Left)', label: 'Rear 3/4 View (Left)' },
                        //     { value: 'Rear 3/4 View (Right)', label: 'Rear 3/4 View (Right)' },
                        //   ]
                        //   }
                        // />
                    )
                }
                {/* {
                    modelParams.secondLoraName &&
                    (
                        <Select
                            w="300px"
                            size="sm"
                            // mt={12}
                            description=""
                            label="Add motion blur"
                            placeholder="Pick value"
                            value={modelParams.motionBlur}
                            onChange={(value) => {
                                setModelParams({
                                    ...modelParams,
                                    motionBlur: value!
                                })
                            }}
                            mb={10}
                            data={[
                                { value: '', label: 'None' },
                                { value: '20 km/h', label: '20 km/h' },
                                { value: '40 km/h', label: '40 km/h' },
                                { value: '60 km/h', label: '60 km/h' },
                                { value: '80 km/h', label: '80 km/h' },
                                { value: '100 km/h', label: '100 km/h' },
                                { value: '120 km/h', label: '120 km/h' },
                                { value: '140 km/h', label: '140 km/h' },
                                { value: '160 km/h', label: '160 km/h' },
                                { value: '180 km/h', label: '180 km/h' },
                                { value: '200 km/h', label: '200 km/h' },
                            ]
                            }
                        />
                    )
                } */}


                <Textarea
                    data-intercom-target={
                        'promptInfo'
                    }
                    w="100%"
                    minRows={7}
                    size="sm"
                    label="Location Prompt"
                    value={modelParams.prompt}
                    description=""
                    placeholder="Street view"
                    onChange={(e) => {
                        setModelParams({
                            ...modelParams,
                            prompt: e.currentTarget.value
                        })
                    }}
                />

                <PromptGeneratorButton
                    image={model.selectedModel?.data?.images[0]}
                    images={model.gallery.find(itm => itm.modelVersion === model.selectedModel?.version)?.images || undefined}
                    onSetPrompt={(prompt) => {
                        setModelParams({
                            ...modelParams,
                            prompt: prompt
                        })
                    }}
                />

                <Text fz={16} mt={12}>Choose aspect ratio</Text>
                <Flex w={'100%'} gap={4} wrap={'wrap'}>
                    {
                        ['1:1', '16:9', '21:9', '2:3', '3:2', '4:5', '5:4', '9:16', '9:21'].map((side) => (
                            <Button
                                size="xs"
                                style={{
                                    width: '80px',
                                    border: '1px solid black',
                                    color: modelParams.aspect_ratio === side ? '#f53c02' : 'black',
                                    fontSize: '14px',
                                }}
                                color="black"
                                variant={modelParams.aspect_ratio === side ? "default" : 'outline'}
                                onClick={() => {
                                    setModelParams({
                                        ...modelParams,
                                        aspect_ratio: side,
                                    })
                                }}
                            >{side}</Button>
                        ))
                    }
                </Flex>

                <Accordion chevronPosition="left" defaultValue="Apples">
                    <Accordion.Item key={'key'} value={'value'}>
                        <Accordion.Control>Advanced settings</Accordion.Control>
                        <Accordion.Panel>
                            <Flex direction={'column'} gap={12}>

                                {
                                    modelParams.secondLoraName &&
                                    <>
                                        <Text
                                            size="xs"
                                            fz={14}
                                            mt={12}
                                        >
                                            Generation steps
                                        </Text>
                                        <Slider
                                            size="xs"
                                            color="#f53c02"
                                            sx={{ maxWidth: 360 }}
                                            mt={0}
                                            min={20}
                                            max={46}
                                            step={1}
                                            label={modelParams.steps}
                                            value={+modelParams.steps}
                                            onChangeEnd={(e) => {
                                                setModelParams((prevState) => ({
                                                    ...prevState,
                                                    steps: +e.toFixed(2),
                                                }))
                                            }}
                                        />
                                        <Text
                                            fz={14}
                                            mt={12}
                                            size="xs"
                                        >
                                            Guidance Scale
                                        </Text>
                                        <Slider
                                            size="xs"
                                            color="#f53c02"
                                            sx={{ maxWidth: 360 }}
                                            mt={0}
                                            min={1}
                                            max={4}
                                            step={0.1}
                                            label={modelParams.cfg}
                                            value={+modelParams.cfg}
                                            onChangeEnd={(e) => {
                                                setModelParams((prevState) => ({
                                                    ...prevState,
                                                    cfg: +e.toFixed(2),
                                                }))
                                            }}
                                        />
                                    </>
                                }

                                <Text
                                    fz={14}
                                    mt={12}
                                    size="xs"
                                    data-intercom-target={
                                        'trainStrength'
                                    }>
                                    Location streight
                                </Text>
                                <Slider
                                    size="xs"
                                    color="#f53c02"
                                    sx={{ maxWidth: 360 }}
                                    mt={0}
                                    min={0}
                                    max={2}
                                    step={0.1}
                                    label={modelParams.lora_scale}
                                    value={+modelParams.lora_scale}
                                    onChangeEnd={(e) => {
                                        setModelParams((prevState) => ({
                                            ...prevState,
                                            lora_scale: e.toFixed(2),
                                        }))
                                    }}
                                />
                                <Text fz={12}>Controls AI creativity. Lower values make the AI more creative, using the model less. Higher values make it follow the model more closely, with less variation. Best results between 0.5 and 1.4</Text>

                                {
                                    modelParams.secondLoraName &&

                                    <Text
                                        size="xs"
                                        fz={14}
                                        mt={12}
                                        data-intercom-target={
                                            'trainStrength'
                                        }>
                                        Car streight
                                    </Text>
                                }
                                {
                                    modelParams.secondLoraName &&
                                    <Slider
                                        size="xs"
                                        color="#f53c02"
                                        sx={{ maxWidth: 360 }}
                                        mt={0}
                                        min={0}
                                        max={2}
                                        step={0.1}
                                        label={modelParams.secondLoraScale}
                                        value={+modelParams.secondLoraScale}
                                        onChangeEnd={(e) => {
                                            setModelParams((prevState) => ({
                                                ...prevState,
                                                secondLoraScale: e.toFixed(2),
                                            }))
                                        }}
                                    />}

                                {
                                    modelParams.secondLoraName &&
                                    <Text fz={12}>Controls The Custom Car LORA creativity.</Text>
                                }
                            </Flex>
                        </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>

                <Flex gap={12} mt={32} justify={'end'}>
                    <Button
                        styles={{
                            root: {
                                color: 'rgba(230, 230, 230, 1)',
                                height: rem(36),

                                fontSize: '14px',
                                background: '#f53c02 !important',
                                border: 'none',
                                position: 'relative',
                            }
                        }}
                        disabled={modelParams.prompt.length === 0}
                        variant="default"
                        onClick={async () => {
                            if (!authStore.currentUser?.access_purchased) {
                              model.setBillingSubscriptionModalOpen()
                              return
                            } 



                            if (modelParams.secondLoraName) {
                                const prompt = modelParams.secondLoraName ? modelParams.prompt : (modelParams.prompt + `, ${selectedModel?.data.description || 'Photograph'} in style of MAGAI`)

                                model.setLatestCustomModelsRun([])
                                for (const perspective of modelParams.carPerspective) {
                                    const pipeline: PipelineParameters = {
                                        provider: 'replicate',
                                        type: 'customModelV1',
                                        modelName: model.selectedModel!.data.name,
                                        modelVersion: model.selectedModel!.version,
                                        prompt: prompt,
                                        aspectRation: modelParams.aspect_ratio,
                                        loraScale: modelParams.lora_scale,
                                        secondLoraScale: modelParams.secondLoraScale,
                                        secondLoraName: modelParams.secondLoraName,
                                        credits: priceMap.CUSTOM_MODEL_GEN,
                                        replicateVersion: model.selectedModel!.version,
                                        steps: modelParams.steps,
                                        cfg: modelParams.cfg,
                                        srcset: [],
                                        color: modelParams.color,
                                        carPerspective: perspective,
                                        motionBlur: modelParams.motionBlur,
                                    }
                                    const res = await model.runCreatePipeline(pipeline);

                                    if (res.error) {
                                        NOTIFICATION_SERVICE.errorNotification(
                                            "Not enough credits"
                                        );
                                        model.setBillingModalOpen(true)
                                    } else {
                                        model.setPipelines(ppp => [{
                                            ...res.data,
                                            status: res.status
                                        }, ...ppp])

                                        model.setLatestCustomModelsRun(runs => [
                                            ...runs,
                                            {
                                                srcset: [],
                                                jobId: res.data.jobId
                                            }
                                        ])
                                    }
                                }
                            } else {

                                const prompt = (modelParams.prompt + `, ${model.selectedModel?.data.description || 'Photograph'} in style of MAGAI`)

                                const pipeline: PipelineParameters = {
                                    provider: 'replicate',
                                    type: 'customModelV1',
                                    modelName: model.selectedModel!.data.name,
                                    modelVersion: model.selectedModel!.version,
                                    prompt: prompt,
                                    aspectRation: modelParams.aspect_ratio,
                                    loraScale: modelParams.lora_scale,
                                    secondLoraScale: modelParams.secondLoraScale,
                                    secondLoraName: modelParams.secondLoraName,
                                    credits: priceMap.CUSTOM_MODEL_GEN,
                                    replicateVersion: model.selectedModel!.version,
                                    steps: modelParams.steps,
                                    cfg: modelParams.cfg,
                                    srcset: [],
                                    color: modelParams.color,
                                    carPerspective: '',
                                    motionBlur: modelParams.motionBlur,
                                }
                                const res = await model.runCreatePipeline(pipeline);

                                if (res.error) {
                                    NOTIFICATION_SERVICE.errorNotification(
                                        "Not enough credits"
                                    );
                                    model.setBillingModalOpen(true)
                                } else {

                                    model.setPipelines([{
                                        ...res.data,
                                        status: res.status
                                    }, ...model.pipelines])

                                    model.setLatestCustomModelsRun([
                                        {
                                            srcset: [],
                                            jobId: res.data.jobId
                                        }
                                    ])
                                }
                            }
                        }}
                    >
                        {generateButtton({ credits: modelParams.secondLoraName ? modelParams.carPerspective.length * priceMap.CUSTOM_MODEL_GEN : priceMap.CUSTOM_MODEL_GEN, intercom: 'customModelGenerateButton' })}
                    </Button>
                </Flex>
            </Flex>

            {
                model.latestCustomModelsRun.length > 0 ? (
                    <Flex
                        w={'70%'}
                        gap={20}
                        direction={"column"}
                    >
                        {
                            model.latestCustomModelsRun.map((run, index) => {
                                if (run.srcset.length > 0) {
                                    return (
                                        <Flex
                                            gap={20}
                                        >
                                            {run.srcset.map((src, index) => (
                                                <Flex direction={'column'}>
                                                    {/* <GearMenu
                                                        size=""
                                                        src={src}
                                                        onEnlarge={() => {
                                                            model.setEnlargeParams({
                                                                ...enlargeParams,
                                                                url: src
                                                            })
                                                            model.setMode(MODE.ENLARGE)
                                                        }}
                                                        onUpscale={() => {
                                                            model.setImageSrc({
                                                                panoramaImage: null,
                                                                url: src,
                                                                modelVersion: null
                                                            });
                                                            model.setMode(MODE.UPSCALE)
                                                        }}
                                                        pickImage={() => {
                                                            setImageSrc({
                                                                panoramaImage: null,
                                                                url: src,
                                                                modelVersion: null
                                                            });
                                                            setMode(MODE.PICK_IMAGE);
                                                        }}
                                                    /> */}
                                                    <ImageObserved
                                                        width={'440px'}
                                                        height={'440px'}
                                                        dark={false}
                                                        src={src}
                                                    />
                                                </Flex>
                                            ))}
                                        </Flex>
                                    )
                                } else {
                                    return (
                                        <Flex w="100%" h="440px" p={32} align={'center'} justify={'center'} direction={'column'}>
                                            <Text color="#f53c02" fz={12}>Generation in progress.</Text>
                                            <Progress radius="lg" size="lg" mt={30} color="#f53c02" value={100} striped animate={true} w={'200px'} />
                                        </Flex>
                                    )
                                }
                            })
                        }
                    </Flex>
                )
                    :
                    <Flex
                        gap={20}
                    >
                        {[1, 2, 3, 4].map((src, index) => (
                            <Flex direction={'column'} w={'440px'} h={'440px'} bg={'#ffffff'}>
                            </Flex>
                        ))}
                    </Flex>
            }
        </Flex>
    );
}