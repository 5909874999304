import { Flex, Button, rem, createStyles, Text, Loader, LoadingOverlay } from "@mantine/core";
import { useRequest } from "ahooks";
import { useState } from "react";
import { NOTIFICATION_SERVICE } from "../../../components/notifications/NotificationService";
import { useStore } from "../../../hooks/use-store";
import { Widget } from "@uploadcare/react-widget";
import ImageObserved from "./Image";

const useStyles = createStyles((theme) => ({
    main: {
        "> div > button": {
            fontSize: '13px !important',
            textAlign: 'center',
            width: '100%',
            textDecoration: 'none',
            fontWeight: '600 !important',
            height: '2.25rem',
            paddingLeft: '1.125rem',
            paddingRight: '1.125rem',
            borderRadius: '0.25rem',
            border: '0.0625rem solid #ced4da',
            backgroundColor: '#fff !important',
            fontFamily: 'Mulish',
        }
    },
}));
export const UploadCarPerspectiveButton = (model: {
    onSetImage: (url: string) => void,
}) => {

    const { classes } = useStyles();


    const [loading, setLoading] = useState<boolean>(false)
    const [localImage, setLocalImage] = useState<string | null>(null)
    const {
        dataStore: { appStore },
    } = useStore();

    const { runAsync: runAsyncConvert } = useRequest(
        async (model: { url: string, removeBackground?: boolean }) => appStore.convertImage(model),
        {
            manual: true,
            onSuccess: (res) => {
                if (res.error) {
                    NOTIFICATION_SERVICE.errorNotification(
                        "Sorry, service is unavailable due to high demand. Please try later"
                    );
                } else {
                    return res.url
                }
            },
        }
    );
    return (
        <Flex direction={'column'}>
            <LoadingOverlay visible={loading} loaderProps={{ children: <Flex gap={8}><Loader color="orange" /><Text>Loading...</Text></Flex> }} />
            {
                !localImage ? (
                    <Flex w="100%" justify={'center'} className={classes.main}>
                        <Widget
                            imagesOnly
                            localeTranslations={{
                                buttons: {
                                    choose: {
                                        images: {
                                            one: 'Upload car perspective'
                                        },
                                    },
                                },
                            }}
                            clearable={false}
                            // @ts-ignore
                            onChange={async (info: {
                                crop?: {
                                    width: number
                                    height: number
                                },
                                cdnUrl: string,
                                originalImageInfo: {
                                    width: number
                                    height: number
                                }
                            }) => {

                                setLoading(true)
                                const res = await runAsyncConvert({ url: info.cdnUrl, removeBackground: false })


                                if (res) {
                                    model.onSetImage(res.url)
                                    setLocalImage(info.cdnUrl)
                                    setLoading(false)
                                } else {
                                    NOTIFICATION_SERVICE.errorNotification(
                                        "Sorry, service is unavailable due to high demand. Please try later"
                                    );
                                    setLoading(false)
                                }
                            }}
                            tabs={"file camera url"}
                            publicKey="35626f59762b63c4244c"
                            previewStep={false}
                        />
                    </Flex>
                ) : (
                    <Flex
                        h="100px"
                        w={'100%'}
                        justify={'space-between'}
                        align={'center'}
                    >
                        <Flex direction={'column'} justify={'left'} w={'145px'}>
                            <Text fz={12}>Uploaded image</Text>
                            <Button
                                styles={{
                                    root: {
                                        color: '#f53c02',
                                        height: rem(28),
                                        fontSize: '10px',
                                        background: 'transparent !important',
                                        border: 'none',
                                        position: 'relative',
                                    }
                                }}
                                variant="default"
                                onClick={async () => {
                                    setLocalImage(null)
                                    model.onSetImage('')
                                }}
                            >
                                Delete
                            </Button>
                        </Flex>

                        <ImageObserved
                            width={'100px'}
                            height={'100px'}
                            src={localImage!}
                        />
                    </Flex>
                )
            }


        </Flex>

    )
}