import { Flex, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

export const Steps = (model: { mainColor: string, active: number, steps: number }) => {
    const { t } = useTranslation();

    return (
        <Flex gap={6}>
            {
                Array.from({ length: model.steps }).map((_, index) => {

                    if (model.active === index + 1) {
                        return <Text
                            style={{
                                color: 'white',
                                padding: '2px 8px',
                                background: model.mainColor,
                                fontSize: '12px',
                                borderRadius: '4px'
                            }}
                        >
                            {t("step")} {index + 1}
                        </Text>
                    } else {
                        return <Text
                            style={{
                                color: 'rgba(190, 190, 190, 1)',
                                padding: '2px 8px',
                                background: 'rgb(255 255 255)',
                                fontSize: '12px',
                                borderRadius: '4px'
                            }}
                        >
                            {index + 1}
                        </Text>
                    }
                })
            }
        </Flex>
    );
}