import { LazyLoadImage } from 'react-lazy-load-image-component'
import logo from './DG_Block_Logo.svg'
const DgLogo = (props: { size: number }) => {
    return (
        <LazyLoadImage
            alt={'image'}
            height={54}
            src={logo}
            // width={154}
            effect="opacity"
            className="lazy-load-image"
            style={{
                cursor: "pointer",
            }}
            wrapperProps={{
                // If you need to, you can tweak the effect transition using the wrapper style.
                style: { objectFit: "contain" },
            }}
        />
    )
}

export default DgLogo